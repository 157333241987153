import styled, { css, keyframes } from 'styled-components'
import {
  Warning as WarningTypography,
  Content as ContentTypography,
} from '../../_settings/typography'

const accordion = keyframes`
  0% {
    height: 0; 
  }
  100% {
    height: 360px;
  }
`

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`

export const Form = styled.form.attrs({
  className: 'bank-slip',
})``

export const Title = styled.h6.attrs({
  className: 'address-search__title',
})`
  ${() => css`
    ${ContentTypography};
    font-weight: 700;
    text-align: left;
    padding: 16px 0;
  `}
`

export const FormItem = styled.div.attrs({
  className: 'address-search__form-item',
})`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
`

export const FormGroup = styled.div.attrs({
  className: 'address-search__form-group',
})`
  display: flex;
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: row;
  gap: 16px;
`

const wrapperModifiers = {
  focus: (theme) => css`
    border-color: ${theme.colors[theme.components.textField.focusBorderColor]};
  `,
  rounded: (theme) => css`
    border-radius: ${theme.components.textField.borderRadius}px;
  `,
  invalid: () => css`
    border-color: var(--alert);
  `,
}

export const WrapperSelectCountry = styled.div.attrs({
  className: 'address-search__wrapper-select-country',
})`
  ${({ theme, inputIsFocused, invalid }) => css`
    ${ContentTypography};
    display: flex;
    position: relative;
    height: 56px;
    border: 1px solid var(--formBorder);
    width: 100%;
    color: ${theme.colors[theme.components.textField.contentColor]};
    ${inputIsFocused && wrapperModifiers.focus(theme)}
    ${theme.components.textField.rounded && wrapperModifiers.rounded(theme)}
    ${invalid && wrapperModifiers.invalid()}

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 10000s;
      -webkit-text-fill-color: var(--content) !important;
    }
  `}
`

export const Warning = styled.div.attrs(({ invalid }) => ({
  className: `text-input__hint${invalid && '--invalid'}`,
}))`
  ${WarningTypography};
  margin-top: 8px;
  color: ${(props) => (props.invalid ? 'var(--alert)' : '')};
`

export const WrapperAnimation = styled.div.attrs(() => ({
  className: `address-search__wrapper-animation`,
}))`
  height: 0;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;

  ${(props) =>
    props.openAccordion &&
    css`
      animation: ${accordion} 500ms;
      height: auto;
    `}
`

export const WrapperFieldsAnimation = styled.div.attrs(() => ({
  className: `address-search__wrapper-fields-animation`,
}))`
  position: relative;
  z-index: 10;
  ${(props) =>
    props.isLoading
      ? css`
          animation: ${fadeOut} 500ms;
          opacity: 0;
        `
      : css`
          animation: ${fadeIn} 1500ms;
          opacity: 1;
        `}
`

export const WrapperSkeleton = styled.div.attrs(() => ({
  className: `address-search__skeleton`,
}))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  z-index: 1;

  ${(props) =>
    props.isLoading &&
    css`
      animation: ${fadeIn} 1500ms;
      opacity: 1;
    `}
`
