import React, { useEffect, useState } from 'react'
import propTypes from 'prop-types'
import { useTheme } from 'styled-components'
import * as S from './styles'

const Button = ({
  disabled,
  fakeDisabled,
  children,
  variant,
  handleClick,
  type,
  progress,
  ...rest
}) => {
  const [Skin, setSkin] = useState(null)
  const theme = useTheme()

  useEffect(() => {
    async function getSkin() {
      const response = await import(
        `./skins/${theme.components.button.skin}/index.jsx`
      )

      setSkin(() => response.default)
    }

    getSkin()
  }, [theme])

  return (
    Skin && (
      <Skin
        style={progress ? { position: 'relative', overflow: 'hidden' } : {}}
        {...rest}
        disabled={disabled}
        fakeDisabled={fakeDisabled}
        variant={variant}
        handleClick={handleClick}
        type={type}
      >
        {progress ? (
          <>
            <S.Progress show={progress.show} duration={progress.duration} />
            <S.Children>{children}</S.Children>
          </>
        ) : (
          <>{children}</>
        )}
      </Skin>
    )
  )
}

Button.defaultProps = {
  variant: 'primary',
  type: 'button',
  disabled: false,
  fakeDisabled: false,
  progress: undefined,
  handleClick: () => {},
}

Button.propTypes = {
  children: propTypes.oneOfType([
    propTypes.string,
    propTypes.array,
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]).isRequired,
  variant: propTypes.oneOf(['primary', 'secondary', 'inverted', 'tertiary']),
  handleClick: propTypes.func,
  type: propTypes.string,
  disabled: propTypes.bool,
  fakeDisabled: propTypes.bool,
  progress: propTypes.shape({
    show: propTypes.bool.isRequired,
    duration: propTypes.number.isRequired,
  }),
}

export default Button
