import React, { useState, useRef, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment-timezone'

import { clearCart, getConfig } from '../../store/modules/cart/actions'
import useHistory from '../../utils/useHistory'

import * as S from './styles'
import Helmet from '../../utils/head'
import useMedia from '../../utils/useMedia'
import Spacer from '../../components/atoms/Spacer'
import Title from '../../components/atoms/Title'
import Subtitle from '../../components/atoms/Subtitle'
import DecorativeDivider from '../../components/atoms/DecorativeDivider'
import Button from '../../components/atoms/Button'
import Dialog from '../../components/organisms/Dialog'
import Steps from '../../components/molecules/CartSteps'
import Loading from '../../components/molecules/Loading'

import Resume from '../../components/widgets/Cart/Resume'
import Info from '../../components/widgets/Cart/Info'
import Card from '../../components/widgets/Card'
import ResumeCard from '../../components/widgets/Cart/ResumeCard'
import Payment from '../../components/widgets/Payment'
import Confirmation from '../../components/widgets/Cart/Confirmation'
import { formatPrice } from '../../utils/format'

const Cart = () => {
  const [isNextStepDisabled, setNextStepDisabled] = useState(false)
  const [dialogPlanNotPurchase, setDialogPlanNotPurchase] = useState({})
  const [step, setStep] = useState(1)
  const [paymentData, setPaymentData] = useState(null)
  const [forwardButton, setForwardButton] = useState('Próximo passo')
  const [backwardButton, setBackwardButton] = useState('Adicionar presentes')
  const [errorDialog, setErrorDialog] = useState({
    show: false,
    content: '',
    topAction: {
      label: 'OK, ENTENDI',
      action: () => {
        setErrorDialog({ ...errorDialog, show: false })
      },
    },
  })
  const [variableValueDialog, setVariableValueDialog] = useState({
    show: false,
    content: '',
    topAction: {
      label: 'Alterar valor',
      action: () => {
        setVariableValueDialog({ ...variableValueDialog, show: false })
      },
    },
    bottomAction: {
      label: 'Manter valor',
      action: () => {
        setVariableValueDialog({ ...variableValueDialog, show: false })
        handleStepChange(2)
      },
    },
  })
  const history = useHistory()
  const InfoRef = useRef()
  const PaymentRef = useRef()
  const dispatch = useDispatch()
  const { cartStep } = useParams()
  const { profile } = useSelector((state) => state.user)
  const { home } = useSelector((state) => state.menu)
  const { language } = useSelector((state) => state.language)
  const { products, infos, config } = useSelector((state) => state.cart)
  const { showHeader } = useSelector((state) => state.ui)
  const [radioChecked, setRadioChecked] = useState(false)
  const [paymentInfoValid, setPaymentInfoValid] = useState(false)
  const [paymentInfoCreditCardValid, setPaymentInfoCreditCardValid] =
    useState(false)

  const [paymentMethod, setPaymentMethod] = useState(null)
  const isFakeDisabled =
    paymentMethod === 'credit-card'
      ? !paymentInfoValid || !paymentInfoCreditCardValid
      : !paymentInfoValid

  const configsLoaded = useMemo(() => {
    return !!Object.keys(config).length
  }, [config])

  const [totalFormatted] = useSelector((state) => {
    const totalGifts = state.cart.products.reduce((totalSum, product) => {
      return totalSum + product.price * product.quantity
    }, 0)

    const cardValue = state.cart.card ? state.cart.card.price : 0

    return [totalGifts + cardValue]
  })

  const returnWeddingDate = useMemo(() => {
    return moment(profile.wedding_datetime)
      .locale(language.htmlTag)
      .format(
        language.htmlTag === 'en' ? 'MMMM DD, YYYY' : 'DD [de] MMMM [de] YYYY'
      )
  }, [language, profile])

  const formatCurrency = (price, locale = 'pt-BR', currency = 'BRL') => {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
    }).format(price)
  }

  const checkMinimumValueChange = async (products) => {
    const productsChangeVariableValue = products
      .filter(function (product) {
        if (product.variable_value) {
          return product.price == product.price_variable_value
        }
      })
      .map((product) => ({
        price: formatCurrency(product.price, language.htmlTag),
        name: product.name,
      }))

    if (productsChangeVariableValue.length) {
      let text = ''

      if (productsChangeVariableValue.length === 1) {
        text = `Deseja realmente manter o valor mínimo de ${productsChangeVariableValue[0].price} do item ${productsChangeVariableValue[0].name} ou prefere surpreender o casal com um valor adicional?`
      } else {
        const nameOfProducts = []

        productsChangeVariableValue.forEach((product) => {
          nameOfProducts.push(product.name)
        })

        const result = nameOfProducts.join(', ').replace(/,([^,]*)$/, ' e$1')

        text = `Deseja realmente manter o valor mínimo dos itens ${result} ou prefere surpreender o casal com um valor adicional?`
      }

      setVariableValueDialog({
        ...variableValueDialog,
        show: true,
        content: text,
      })
    } else {
      handleStepChange(2)
    }
  }

  const handleStepChange = (target, isFromLanguage = false) => {
    if (!isFromLanguage) {
      window.scrollTo({ top: 0 })
    }

    setStep(target)

    switch (target) {
      case 1:
        setForwardButton('Próximo passo')
        setBackwardButton('Adicionar presentes')
        history.replace(`/cart/resume`)
        break
      case 2:
        setForwardButton('Próximo passo')
        setBackwardButton('Voltar para o carrinho')
        history.replace(`/cart/infos`)
        break
      case 3:
        setForwardButton('Finalizar compra')
        setBackwardButton('Voltar uma etapa')
        history.replace(`/cart/payment`)
        break
      case 4:
        dispatch(clearCart())
        setForwardButton('Imprimir página')
        setBackwardButton('Voltar para o site')
        history.replace(`/cart/confirmation`)
        break
      default:
    }
  }

  const checkPlanForPayment = () => {
    if (
      config?.paymentRule
        ? totalFormatted > config.paymentRule.max_gift_value
        : false
    ) {
      setDialogPlanNotPurchase({
        contentHtml: `Desculpe, este site está em período de teste, por isso não é possível comprar acima de ${formatPrice(
          config.paymentRule.max_gift_value
        )} enquanto não houver um plano ativo. Caso queira presentear ${
          profile.couple_name
        }, verifique os itens adicionados ao carrinho.`,
        isOpen: true,
      })
      return false
    }

    return true
  }

  const handleForward = async () => {
    let response
    switch (step) {
      case 1:
        checkMinimumValueChange(products)
        break
      case 2:
        if (checkPlanForPayment()) {
          response = InfoRef.current.handleSubmit()
          if (response) handleStepChange(3)
          else handleStepChange(2)
        }
        break
      case 3:
        setForwardButton('Processando o pagamento...')
        response = await PaymentRef.current.handleSubmit()

        if (response.status === 'success') {
          setPaymentData({
            ...response.message,
            products: response.products,
            card: response.card,
          })
          handleStepChange(4)
        } else {
          setErrorDialog({
            ...errorDialog,
            show: true,
            content: response.message,
          })
          handleStepChange(3)
        }
        break
      case 4:
        window.print()
        break
      default:
    }
  }

  const handleBackToHome = () => {
    history.push(`/${home.slug}`)
  }

  const handleBackward = () => {
    switch (step) {
      case 1:
        history.goBack()
        break
      case 2:
        handleStepChange(1)
        break
      case 3:
        handleStepChange(2)
        break
      case 4:
        handleBackToHome()
        break
      default:
    }
  }

  const getPaymentMethod = useMemo(() => {
    if (paymentData?.payment_method === 'BANK_SLIP') {
      return 'bank-slip'
    }

    if (paymentData?.payment_method === 'PIX') {
      return 'pix'
    }

    return 'credit-card'
  }, [paymentData])

  const checkProductVariableValue = (products) => {
    setNextStepDisabled(
      !!products.filter(function (product) {
        if (product.variable_value) {
          return product.price < product.price_variable_value
        }
      }).length
    )
  }

  useEffect(() => {
    switch (cartStep) {
      case 'resume':
        handleStepChange(1)
        break
      case 'infos':
        handleStepChange(2)
        break
      case 'payment':
        if (!Object.keys(infos).length) handleStepChange(2)
        else handleStepChange(3)
        break
      default:
        if (products.length) history.replace(`/cart/resume`)
        else handleBackward(1)
        break
    }

    if (!configsLoaded) {
      dispatch(getConfig(profile.couple_id))
    }
  }, [])

  useEffect(() => {
    if (!products.length && step !== 4) handleBackToHome()
    checkProductVariableValue(products)
  }, [products])

  const isMobile = useMedia('(max-width: 768px)')

  return (
    <>
      <Loading loaded={configsLoaded} />
      {configsLoaded && (
        <S.Wrapper additionalMarginTop={showHeader ? 1 : 0}>
          <Helmet pageName="Carrinho" lang={language.htmlTag} />
          <S.FixedTop>
            <Spacer size="medium" />
          </S.FixedTop>
          {step === 1 && (
            <>
              <Title className="notranslate">{`${profile.bride_name} & ${profile.groom_name}`}</Title>
              <Subtitle>{returnWeddingDate}</Subtitle>
              <DecorativeDivider />
              {!isMobile && <Spacer size="medium" />}
              <Resume />
              <Spacer size="small" />
            </>
          )}
          <Steps step={step} />

          {step === 2 && (
            <>
              {dialogPlanNotPurchase?.isOpen && (
                <Dialog
                  contentHtml={dialogPlanNotPurchase.contentHtml}
                  isButtonWidthAuto={true}
                  topAction={{
                    label: 'OK, ENTENDI',
                    action() {
                      setDialogPlanNotPurchase({})
                    },
                  }}
                />
              )}
              <Subtitle>Preencha seus dados e deixe uma mensagem</Subtitle>
              <Spacer size="small" />
              <Info ref={InfoRef} />
              {config.cardAvailable && (
                <>
                  <Card />
                  {!config.embeddedCard && <ResumeCard />}
                </>
              )}
              <Spacer size="small" />
            </>
          )}
          {step === 3 && (
            <>
              <Subtitle>Escolha uma das formas de pagamento:</Subtitle>
              <Spacer size="small" />
              <Payment
                ref={PaymentRef}
                setRadioChecked={setRadioChecked}
                setPaymentInfoValid={setPaymentInfoValid}
                setPaymentInfoCreditCardValid={setPaymentInfoCreditCardValid}
                setPaymentMethod={setPaymentMethod}
                paymentMethod={paymentMethod}
              />
              <Spacer size="small" />
            </>
          )}
          {step === 4 && (
            <>
              <Confirmation
                {...paymentData}
                paymentMethod={getPaymentMethod}
                paymentId={
                  paymentData.payment_method === 'BANK_SLIP'
                    ? paymentData.bank_slip_id
                    : paymentData.tid
                }
                date={paymentData.date}
                cardBrand={paymentData.brand}
                bankSlipLink={paymentData.bank_slip_url}
                paymentInfo={paymentData.payment_params}
                products={paymentData.products}
                card={paymentData.card}
                config={config}
              />
              <Spacer size="small" />
            </>
          )}
          <S.ControlsWrapper>
            <Button variant="secondary" handleClick={handleBackward}>
              {backwardButton}
            </Button>
            <Button
              data-testid="NextButton"
              handleClick={handleForward}
              variant="primary"
              disabled={
                forwardButton === 'Processando o pagamento...' ||
                isNextStepDisabled ||
                (step === 3 && !radioChecked)
              }
              fakeDisabled={step === 3 && isFakeDisabled}
            >
              {forwardButton}
            </Button>
          </S.ControlsWrapper>
          <Spacer size="small" />
          {errorDialog.show && (
            <Dialog
              isButtonWidthAuto={errorDialog.isButtonWidthAuto}
              contentHtml={errorDialog.content}
              topAction={errorDialog.topAction}
            />
          )}
          {variableValueDialog.show && (
            <Dialog
              contentHtml={variableValueDialog.content}
              topAction={variableValueDialog.topAction}
              bottomAction={variableValueDialog.bottomAction}
            />
          )}
        </S.Wrapper>
      )}
    </>
  )
}

Cart.propTypes = {
  match: PropTypes.object.isRequired,
}

export default Cart
