import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from 'styled-components'

import * as S from './styles'

const Flag = ({ women }) => {
  const theme = useTheme()

  return (
    <S.Wrapper>
      <g fill="none" fillRule="evenodd">
        <g>
          <S.Flag d="M0 0 v127 l20 -10 20 10 V0" />
          <S.Lines d="M4.5 0 v124 M35.5 0 v124" />
        </g>
        <S.Text transform="translate(0 -1) rotate(-90 19.5 59.5)">
          <tspan
            x={
              theme.components.flag.textTransform === 'uppercase'
                ? '-15.921'
                : '-8.921'
            }
            y="64.5"
          >
            {women ? 'Casadas' : 'Casados'}
          </tspan>
        </S.Text>
      </g>
    </S.Wrapper>
  )
}

Flag.defaultProps = {
  women: false,
}

Flag.propTypes = {
  women: PropTypes.bool,
}

export default Flag
