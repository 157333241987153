/* eslint-disable react/display-name */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useMemo,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'

import { formatPrice } from '../../../../utils/format'
import api from '../../../../services/api'

import { ReactComponent as VisaIcon } from '../../../../assets/img/card-brands/visa.svg'
import { ReactComponent as MasterIcon } from '../../../../assets/img/card-brands/master.svg'
import { ReactComponent as AmexIcon } from '../../../../assets/img/card-brands/amex.svg'
import { ReactComponent as EloIcon } from '../../../../assets/img/card-brands/elo.svg'
import { ReactComponent as HipercardIcon } from '../../../../assets/img/card-brands/hipercard.svg'
import { ReactComponent as CvvIcon } from '../../../../assets/img/cod-card.svg'
import BrandIcon from '../../../../assets/img/BrandIcon'
import TextField from '../../../atoms/TextField'
import SelectField from '../../../atoms/SelectField'

import cardValidator from '../../../../utils/creditCard/validator'

import schema from './validations'

import * as S from './styles'

const CreditCardForm = forwardRef(
  ({ setCallbackInstallment, setPaymentInfoCreditCardValid }, ref) => {
    const { watch, register, setValue, getValues, handleSubmit, formState } =
      useForm({
        mode: 'onTouched',
        defaultValues: {
          installment: '',
        },
        resolver: yupResolver(schema),
      })

    const { errors, isDirty } = formState

    const watchedNumberField = watch('cardNumber', '')
    const watchedInstallment = watch('installment')

    const [brandData, setBrandData] = useState(null)
    const [invalidCardDate, setInvalidCardDate] = useState(false)
    const [installments, setInstallments] = useState([])
    const { profile } = useSelector((state) => state.user)
    const { language } = useSelector((state) => state.language)

    const [products, card] = useSelector((state) => [
      state.cart.products,
      state.cart.card,
    ])

    const getMonths = () => {
      const months = []

      // eslint-disable-next-line
    for (let i = 1; i <= 12; i++) {
        months.push({ value: `${i}`, label: `0${i}`.slice(-2) })
      }

      return months
    }

    const getYears = () => {
      const years = []
      const currentYear = new Date().getFullYear()

      // eslint-disable-next-line
    for (let i = currentYear; i <= currentYear + 10; i++) {
        years.push({ value: `${i}`, label: `${i}` })
      }

      return years
    }

    const getInstallments = async () => {
      if (!brandData?.brand) return

      const { data } = await api.get('/api/v1/payment/installments', {
        params: {
          couple_id: profile.couple_id,
          store_products_id: `{"data": [${products
            .reduce(
              (string, product) =>
                `${string}{"id_product":${product.id},"store_id":${product.store_id},"is_quota":${product.is_quota},"quantity":${product.quantity},"price":${product.price}},`,
              ''
            )
            .slice(0, -1)}]}`,
          card: !!card,
          credit_card_brand: brandData.brand, // change,
        },
      })

      const installments = data.data.map((item) => {
        if (language.htmlTag === 'en') {
          return {
            value: item.installment,
            price: item.price,
            total: item.total || item.price,
            label:
              item.installment > 1
                ? `${item.installment}x of ${formatPrice(
                    item.price,
                    language.htmlTag
                  )}*`
                : `${item.installment}x of ${formatPrice(
                    item.price,
                    language.htmlTag
                  )}`,
          }
        }

        return {
          value: item.installment,
          price: item.price,
          total: item.total || item.price,
          label:
            item.installment > 1
              ? `${item.installment}x de ${formatPrice(item.price)}*`
              : `${item.installment}x de ${formatPrice(item.price)}`,
        }
      })

      setInstallments(installments)
    }

    const validateCardDate = ({ month, year }, overwrite = false) => {
      const currentMonth = new Date().getMonth() + 1
      const currentYear = new Date().getFullYear()
      const selectedMonth = Number(month)
      const selectedYear = Number(year)

      if ((selectedMonth && selectedYear) || overwrite) {
        if (
          selectedYear > currentYear ||
        (selectedYear === currentYear && selectedMonth >= currentMonth) // eslint-disable-line
        ) {
          setInvalidCardDate(false)
        } else {
          setInvalidCardDate(true)
        }
      } else {
        setInvalidCardDate(false)
      }
    }

    const handleMask = useMemo(() => {
      if (brandData?.brand) {
        return `${brandData.brand}CreditCard`
      }

      return 'defaultCreditCard'
    }, [brandData])

    const onSubmit = (data) => {
      return { ...data }
    }

    const returnValidationToParent = () => {
      handleSubmit(onSubmit)()

      const {
        cardCvv,
        cardYear,
        cardName,
        cardMonth,
        cardNumber,
        installment,
      } = getValues()

      if (
        !cardCvv.length ||
        !cardYear.length ||
        !cardName.length ||
        !cardMonth.length ||
        !cardNumber.length ||
        !installment.length
      ) {
        return false
      }

      const { isValid } = cardValidator(cardNumber)
      if (!isValid) {
        return false
      }

      return {
        cardConfig: brandData,
        cardNumber,
        cardName,
        cardMonth,
        cardYear,
        cardCvv,
        installment,
      }
    }

    useImperativeHandle(ref, () => ({
      validateForm: returnValidationToParent,
    }))

    const returnTotalFormatPrice = () => {
      const { installment } = getValues()

      return formatPrice(
        installments.find((item) => item.value == installment).total
      )
    }

    useEffect(() => {
      const cardInfo = cardValidator(watchedNumberField || '')

      if (cardInfo) {
        setBrandData({
          ...cardInfo,
          icon: <BrandIcon brand={cardInfo.brand} />,
        })
      } else {
        setValue('installment', '', { shouldValidate: false })
        setBrandData(null)
      }
    }, [watchedNumberField])

    useEffect(() => {
      if (brandData?.isValid) {
        getInstallments()
      } else {
        setInstallments([])
        setValue('installment', '', { shouldValidate: false })
      }
    }, [brandData?.isValid])

    useEffect(() => {
      if (typeof setCallbackInstallment === 'function') {
        setCallbackInstallment(watchedInstallment)
      }
    }, [watchedInstallment])

    useEffect(() => {
      setPaymentInfoCreditCardValid(isDirty && Object.keys(errors).length === 0)
    }, [formState])

    return (
      <S.Form>
        <S.Flags>
          <S.FlagsText>Pague com os cartões de crédito:</S.FlagsText>

          <S.FlagsList>
            <S.FlagsItem>
              <VisaIcon aria-label="Visa" />
            </S.FlagsItem>

            <S.FlagsItem>
              <MasterIcon aria-label="Mastercard" />
            </S.FlagsItem>

            <S.FlagsItem>
              <AmexIcon aria-label="Amex" />
            </S.FlagsItem>

            <S.FlagsItem>
              <EloIcon aria-label="Elo" />
            </S.FlagsItem>

            <S.FlagsItem>
              <HipercardIcon aria-label="Hipercard" />
            </S.FlagsItem>
          </S.FlagsList>
        </S.Flags>

        <S.Title>Dados do cartão de crédito:</S.Title>

        <TextField
          required
          type="text"
          label="cardNumber"
          register={register}
          name="cardNumber"
          mask={handleMask}
          icon={brandData?.icon}
          invalid={!!errors?.cardNumber?.message}
          helperText={errors?.cardNumber?.message}
          placeholder="Número do cartão"
        />
        <TextField
          required
          type="text"
          label="cardName"
          name="cardName"
          register={register}
          placeholder="Nome no cartão"
          invalid={!!errors?.cardName?.message}
          helperText={errors?.cardName?.message}
        />

        <S.Line>
          <S.Content>
            <S.Label>Validade</S.Label>
            <S.Option>
              <S.Col width={84}>
                <SelectField
                  required
                  fullWidth
                  id="cardMonth"
                  name="cardMonth"
                  label="cardMonth"
                  placeholder="Mês"
                  register={register}
                  options={getMonths()}
                  onChange={(ev) =>
                    validateCardDate({
                      month: ev.target.value,
                      year: watch('cardYear'),
                    })
                  }
                  invalid={invalidCardDate}
                />
              </S.Col>
              <S.Col width={115}>
                <SelectField
                  required
                  fullWidth
                  id="cardYear"
                  label="cardYear"
                  name="cardYear"
                  placeholder="Ano"
                  register={register}
                  options={getYears()}
                  onChange={(ev) =>
                    validateCardDate({
                      month: watch('cardMonth'),
                      year: ev.target.value,
                    })
                  }
                  invalid={invalidCardDate}
                />
              </S.Col>
            </S.Option>
          </S.Content>
          {invalidCardDate && (
            <S.Warning invalid>A data informada é inválida</S.Warning>
          )}
        </S.Line>
        <TextField
          required
          type="text"
          label="cardCvv"
          name="cardCvv"
          register={register}
          invalid={!!errors?.cardCvv?.message}
          helperText={errors?.cardCvv?.message}
          placeholder="Código de segurança"
          maxLength={brandData?.cvvSize || 3}
          icon={<CvvIcon />}
          mask="number"
        />
        <S.Line>
          <S.Content>
            <S.Label>Parcelas</S.Label>
            <S.Option>
              <S.Col width={160}>
                <SelectField
                  required
                  fullWidth
                  id="installment"
                  name="installment"
                  label="installment"
                  register={register}
                  placeholder="Parcelas"
                  options={installments}
                  invalid={!!errors?.installment?.message}
                />
              </S.Col>
            </S.Option>
          </S.Content>
          {!!errors?.installment?.message && (
            <S.Warning invalid>{errors?.installment?.message}</S.Warning>
          )}
        </S.Line>
        {watch('installment') > 1 && (
          <S.Line>
            <S.Content>
              <S.Label>* Valor total parcelado:</S.Label>
              <S.Option>{returnTotalFormatPrice()}</S.Option>
            </S.Content>
          </S.Line>
        )}
      </S.Form>
    )
  }
)

export default CreditCardForm
