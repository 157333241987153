import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import * as S from './styles'

function FloatingImage({
  zIndex,
  width,
  height,
  left,
  right,
  top,
  bottom,
  transform,
  background,
  backgroundSize,
  desktopInfiniteBackgroundSize = false,
  backgroundImage,
  backgroundPosition,
  maskImage,
  tablet,
  mobile,
}) {
  const [backgroundImagePath, setBackgroundImagePath] = useState(null)
  const [backgroundImageTabletPath, setBackgroundImageTabletPath] =
    useState(null)
  const [backgroundImageMobilePath, setBackgroundImageMobilePath] =
    useState(null)

  useEffect(() => {
    if (backgroundImage) {
      setBackgroundImagePath(`/assets/floating-image/${backgroundImage}`)
    }

    if (tablet.backgroundImage) {
      setBackgroundImageTabletPath(
        `/assets/floating-image/${tablet.backgroundImage}`
      )
    }

    if (mobile.backgroundImage) {
      setBackgroundImageMobilePath(
        `/assets/floating-image/${mobile.backgroundImage}`
      )
    }
  }, [backgroundImage, tablet.backgroundImage, mobile.backgroundImage])

  return (
    <floating-image>
      <S.Container
        desktopInfiniteBackgroundSize={desktopInfiniteBackgroundSize}
        zIndex={zIndex}
        width={width}
        height={height}
        left={left}
        right={right}
        top={top}
        bottom={bottom}
        transform={transform}
        maskImage={maskImage}
        background={background}
        backgroundSize={backgroundSize}
        backgroundImage={backgroundImagePath}
        backgroundPosition={backgroundPosition}
        tablet={{
          width: tablet.width,
          height: tablet.height,
          left: tablet.left,
          right: tablet.right,
          top: tablet.top,
          bottom: tablet.bottom,
          background: tablet.background,
          backgroundSize: tablet.backgroundSize,
          backgroundPosition: tablet.backgroundPosition,
          backgroundImage: backgroundImageTabletPath,
        }}
        mobile={{
          width: mobile.width,
          height: mobile.height,
          left: mobile.left,
          right: mobile.right,
          top: mobile.top,
          bottom: mobile.bottom,
          background: mobile.background,
          backgroundSize: mobile.backgroundSize,
          backgroundPosition: mobile.backgroundPosition,
          backgroundImage: backgroundImageMobilePath,
        }}
      />
    </floating-image>
  )
}

FloatingImage.defaultProps = {
  zIndex: '0',
  width: '',
  height: '',
  left: '',
  right: '',
  top: '',
  bottom: '',
  transform: '',
  background: '',
  backgroundSize: '',
  backgroundImage: '',
  maskImage: false,
  tablet: {
    width: '',
    height: '',
    left: '',
    right: '',
    top: '',
    bottom: '',
    transform: '',
    background: '',
    backgroundSize: '',
    backgroundImage: '',
  },
  mobile: {
    width: '',
    height: '',
    left: '',
    right: '',
    top: '',
    bottom: '',
    transform: '',
    background: '',
    backgroundSize: '',
    backgroundImage: '',
  },
}

FloatingImage.propTypes = {
  zIndex: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  left: PropTypes.string,
  right: PropTypes.string,
  top: PropTypes.string,
  bottom: PropTypes.string,
  transform: PropTypes.string,
  background: PropTypes.string,
  backgroundSize: PropTypes.string,
  backgroundImage: PropTypes.string,
  maskImage: PropTypes.bool,
  tablet: PropTypes.object, //eslint-disable-line
  mobile: PropTypes.object, //eslint-disable-line
}

export default FloatingImage
