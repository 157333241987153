import styled, { keyframes } from 'styled-components'

import { Content as ContentTypography } from '../../_settings/typography'
import { ReactComponent as BankSlip } from '../../../assets/img/bank-slip.svg'
import { ReactComponent as CreditCard } from '../../../assets/img/credit-card.svg'
import { ReactComponent as Pix } from '../../../assets/img/icon-pix.svg'

const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
`

export const Wrapper = styled.div.attrs({
  className: 'payment',
})`
  width: 100%;
  padding: 80px 15px;
  background: ${({ theme }) =>
    theme.colors[theme.components.cart.wrapperBackground]};
  display: flex;
  justify-content: center;
`

export const FormsWrapper = styled.div.attrs({
  className: 'payment__options',
})`
  width: 100%;
  max-width: 350px;
`

export const PaymentOption = styled.div.attrs({
  className: 'payment__option',
})`
  animation: ${fadeIn} 300ms;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`

export const Header = styled.div.attrs({
  className: 'payment__option-header',
})`
  padding: 0 20px;
  width: 100%;
  height: 60px;
  border: 1px solid var(--formBorder);
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: ${(props) => (props.selected && !props.disabled ? '1' : '0.36')};
  border-radius: ${({ theme }) =>
    theme.components.payment.borderRadius
      ? `${theme.components.payment.borderRadius}px`
      : '0'};
  cursor: pointer;
`

export const OptionSelector = styled.div`
  display: flex;
  align-items: center;
`

export const OptionLabel = styled.label.attrs({
  className: 'payment__option-label',
})`
  ${ContentTypography};
  margin-left: 12px;
  cursor: pointer;
`

export const BankSlipIcon = styled(BankSlip)`
  path {
    fill: var(--main);
  }
`

export const CreditCardIcon = styled(CreditCard)`
  path {
    fill: var(--main);
  }
`

export const PixIcon = styled(Pix)`
  path:nth-child(2),
  path:nth-child(3) {
    fill: var(--main);
  }
`

export const Form = styled.div.attrs({
  className: 'payment__option-form',
})`
  display: ${(props) => (props.selected ? 'block' : 'none')};
  padding: 16px 0;
`
