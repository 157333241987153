import { getCountries } from 'react-phone-number-input/input'
import moment from 'moment-timezone'

export default function getPhoneRegionCode() {
  try {
    const { region } = new Intl.Locale(
      moment.locale().toLocaleUpperCase() || navigator.language
    )
    if (getCountries().includes(region)) return region
    return 'BR'
  } catch (error) {
    return ''
  }
}
