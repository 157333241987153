import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Modal from '../Modal'
import Search from '../Search'
import { countries, getCountryById } from '../../../utils/countries'
import * as S from './styles'

const SelectCountry = ({
  country,
  onChange,
  onFocus = () => {},
  onBlur = () => {},
  title = 'Selecione seu DDI',
  fullWidth = false,
  showCountryName = false,
  placeholder = '',
  removeFlags = [],
}) => {
  const [showModal, setShowModal] = useState(false)
  const currentCountry = getCountryById(country)

  const onSelect = (country) => {
    onCloseModal()
    onChange(country.id)
  }

  const onKeyDownFlag = (e) => {
    if (e.key === 'Enter') {
      setShowModal(true)
    }
    if (e.key === 'Escape') {
      onCloseModal()
    }
  }

  const onCloseModal = () => {
    setShowModal(false)
    onBlur()
  }

  return (
    <>
      <S.FlagWrapper
        role="button"
        tabIndex="0"
        onClick={() => setShowModal(true)}
        onKeyDown={onKeyDownFlag}
        data-testid="SelectCountrySelectDDI"
        fullWidth={fullWidth}
        onFocus={onFocus}
        showCountryName={showCountryName}
      >
        {currentCountry?.flag && (
          <S.Flag src={currentCountry.flag} alt="icon country" />
        )}
        {(placeholder || showCountryName) && (
          <S.countryName>{currentCountry?.name || placeholder}</S.countryName>
        )}
        <S.Arrow />
      </S.FlagWrapper>

      <Modal title={title} show={showModal} close={() => onCloseModal()}>
        <Search
          options={countries.filter((c) => !removeFlags.includes(c.id)) || []}
          onSelect={onSelect}
          onBlur={onBlur}
          filter={(search, country) => {
            const regex = new RegExp(search, 'gi')
            return regex.test(country.callingCode) || regex.test(country.name)
          }}
          renderItem={(country) => (
            <S.Option>
              {showCountryName ? (
                <>
                  <img src={country.flag} />
                  <span>{country.name}</span>
                </>
              ) : (
                <>
                  <span>+{country.callingCode}</span>
                  <span>{country.name}</span>
                  <img src={country.flag} />
                </>
              )}
            </S.Option>
          )}
        />
      </Modal>
    </>
  )
}

SelectCountry.propTypes = {
  country: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

export default SelectCountry
